import logo from './logo.svg';
import './App.css';
import './App.scss';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import DocumentMeta from 'react-document-meta';
import Modal from 'react-bootstrap/Modal'

const meta = {
  title: 'Champagne Vallois-Ferat - Premier Cru',
  description: 'Site internet du Champagne Vallois-Ferat à Bergères-Lès-Vertus',
  canonical: 'https://vallois-ferat.fr',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'Champagne,Vallois-Ferat,Bergeres,bergeres-les-vertus'
    }
  }
}

function AppHeader() {
  return (
    <header className='App-header'>
    <Navbar variant='dark' spacing='4'
        className="justify-content-center navbar-custom" fixed="top">
          <Nav>
          <Nav.Link href="#shop" className='mx-3'>Notre Boutique</Nav.Link>
            <Navbar.Brand>VALLOIS-FERAT</Navbar.Brand>
            <Nav.Link
              //href="mailto:marie-odile.vallois@wanadoo.fr"
              onClick={
                () => window.location.replace("/#contact-us")
              }>Nous Contacter</Nav.Link>
          </Nav>
      </Navbar>
    </header>
  )
}

function AppBody() {
  return (
    <div className="AppBody">
      <body className='App-body'>
        <h1>VALLOIS</h1>
        <h1>FERAT</h1>
        <h2>Champagne Premier Cru</h2>
        <h3>à Bergères-Lès-Vertus</h3>
        <p>&nbsp;</p>

        <h4 id="contact-us">Nous Contacter</h4>

        <p>Afin de convenir d'un rendez-vous ou de recevoir nos tarifs,</p>
        <p>il est préférable de nous joindre par téléphone ou de nous envoyer un email.</p>
        <p>☎️ +33 3 26 52 19 78</p>
        <p>📱 +33 6 76 28 90 71</p>
        <p>📱 +33 6 80 30 01 16</p>
        <p>📧 <a className="link-custom" href="mailto:champagne.vallois-ferat@orange.fr">champagne.vallois-ferat@orange.fr</a></p>
        <p>✉️ Chemin du mazet, 51130, Bergères Les Vertus</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </body>
    </div>
  )
}

function App() {
  return (
    <div className="App">
      <DocumentMeta {...meta} />
      <AppHeader />
      <AppBody />
    </div>
  );
}

export default App;
